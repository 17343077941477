@font-face {
  font-family: "domainedisplay_regular";
  src: url("./DomaineDispNar-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "roboto_regular";
  src: url("./Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0 0 0 0;
  box-sizing: border-box;
}

*:focus {
  outline-width: 0px;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.primary_font {
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif;
  color: #2b1f30;
}

.form_title {
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif;
  font-size: 42px;
}

.secondary_font {
  font-family: "roboto_regular";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #2b1f30;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footerListGroupItem {
  border: none;
  font-family: "roboto_regular";
  background-color: #ebf9ff !important;
}

.subFooterItem {
  padding: 0.75rem 1.25rem;
  color: #676767;
}

.subFooterItem a {
  font-family: "roboto_regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 21px;
}

.subFooterItem a:last-child {
  margin-right: 0;
}

.marqueeElement {
  display: inline-block;
  margin: 0 20px;
  float: left;
  transition: all 0.2s ease-out;
  padding-right: 15px;
  padding-left: 15px;
}

.marqueeElement:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.marquee-image-row {
  justify-content: center;
}

.marquee-image {
  max-height: 100px;
}

.marquee-content {
  margin: 0 5px;
  justify-content: center;
}

.bold {
  font-weight: bolder;
}

.marquee-footer {
  margin: 0 5px -15px 0;
  justify-content: start;
}

/* Each marquee item is around 395 px wide in total,
therefore, total marquee width is about 395 * # of marquee Items */
.marquee {
  height: 500px;
  /* min-width: 1580px !important; */
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  margin: 10px auto;
  z-index: 2;
}

.marquee--inner {
  display: flex;
  align-content: center;
  align-items: center;
  width: 200%;
  position: absolute;
  animation: marquee 22s linear infinite;
  z-index: 2;
  margin-top: 30px;
}

.marquee--inner:hover {
  animation-play-state: paused;
}

/* Each marquee item is around 395 px wide in total,
therefore, total marquee width is about 395 * # of marquee Items */
.marqueeItem {
  background: linear-gradient(transparent 15%, #ebf9ff 15% 85%);
  max-width: 350px !important;
  min-width: 350px !important;
  width: 350px !important;
  font-size: 15px;
  padding-bottom: 10px;
}

.marqueeElement:nth-child(odd) {
  margin-top: 50px;
}

.marqueeElement:nth-child(even) {
  margin-bottom: 50px;
}

.email-form {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #f4f4f5;
  font-size: 1.1em !important;
}

.email-form:hover {
  border-bottom-color: #1886df;
  cursor: pointer;
}

button {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  /* border-bottom-color: transparent; */
  border-bottom-color: #f4f4f5;
}

button:hover {
  border-bottom-color: #1886df;
}

.marquee::after {
  z-index: 3;
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(
    to right,
    #fff 0,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 75%,
    #fff 100%
  );
  height: 510px;
  width: 100%;
  pointer-events: none;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.light-blue-background {
  background-color: #ebf9ff;
}

.dark-blue-background {
  background-color: #1886df;
}

.white-background {
  background-color: white;
}

.near-black-background {
  background-color: #2b1f31;
}

.center-in-row {
  justify-content: center;
}

.oval {
  position: absolute;
  z-index: 0;
  margin-top: -50px;
  margin-left: -50px;
}

.features-oval {
  position: absolute;
  z-index: 1;
  margin-top: -25px;
  margin-left: -50px;
}

.marquee-oval {
  position: absolute;
  z-index: 1;
  margin-left: 1000px;
  padding-top: 350px;
}

.marquee-line {
  position: absolute;
  z-index: 1;
  margin-left: 600px;
  padding-top: 25px;
}

.marquee-stroke10 {
  position: absolute;
  z-index: 1;
  margin-left: 200px;
  margin-top: 300px;
}

.line {
  position: absolute;
  z-index: 0;
  margin-top: -50px;
  margin-left: 200px;
}

.features-line {
  position: absolute;
  z-index: 1;
  margin-top: -20px;
  margin-left: 300px;
}

.partnerImages {
  display: flex;
  justify-content: space-around;
  height: auto;
  width: 100%;
}

.imageObjectTitle {
  display: flex;
  justify-content: center;
  text-align: center;
}

.left-side-header-title {
  margin-bottom: 20px;
}

.left-side-header-blurb {
  max-width: 400px;
}

.top_row_left_col {
  display: flex;
  align-items: center;
  margin-right: 100px;
}

.left-side-header-title-large-font {
  font-size: 50px;
}

.blue-header-svg {
  z-index: 2;
}

.mobile-blue-header-svg {
  z-index: 2;
  height: auto;
  width: 400px;
}

.mobile-get-a-demo-blue-header-svg {
  z-index: 2;
  height: auto;
  width: 350px;
}

.mobile-thank-you-svg {
  z-index: 3;
  height: auto;
  width: 350px;
}

.full-width-light-blue {
  margin: 0 -9999rem;
  /* add back negative margin value */
  padding: 0.25rem 9999rem;
  background: #ebf9ff;
}

.full-width-near-black {
  margin: 0 -9999rem;
  /* add back negative margin value */
  padding: 0.25rem 9999rem;
  background: #2b1f31;
}

.full-width-dark-blue {
  margin: 0 -9999rem;
  /* add back negative margin value */
  padding: 0.25rem 9999rem;
  background: #1786df;
}

.text-align-center {
  text-align: center;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.max-height-width-100 {
  max-width: 100%;
  max-height: 100%;
}

.height-80 {
  height: 80px;
}

.sharp-corners-button {
  border-radius: 0px !important;
}

.schoolTestimonialsTitle {
  justify-content: center;
}
