.partnersPageHeader {
  background: #EBF9FF;
  padding-top: 130px;
  padding-bottom: 150px;
  align-items: center;
  flex-direction: column;
  color: #000;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.partnersPageHeaderTitle {
  font-family: 'domainedisplay_regular';
  font-size: 64px;
  line-height: 84px;
  margin-bottom: 40px;
}

.partnersPageHeaderDescription {
  width: 637px;
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin-bottom: 45px;
}

.partnersPageHeaderArc {
  position: absolute;
  left: -200px;
  right: -200px;
  bottom: -145px;
  height: 200px;
  border-radius: 50%;
  background: #fff;
}

.partnersContainer {
  padding: 95px 108px 120px;
}

.partnerItemsGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 120px;
}

.partnerItemsGroup:last-child {
  margin-bottom: 0;
}

.partnerItem {
  width: 365px;
  display: flex;
  font-family: 'Roboto';
  color: #000;
}

.partnerLogo {
  width: 125px;
  height: 125px;
  margin-right: 24px;
}

.partnerInfo {
  position: relative;
  padding-top: 38px;
}

.partnerTag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 19px;
  background: #EBF9FF;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #1886DF;
}

.partnerTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin-bottom: 5px;
}

.partnerDescription {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-bottom: 0;
}

@media screen and (max-width: 1223px) and (min-width: 768px) {
  .partnersPageHeader {
    padding-top: 86px;
    padding-bottom: 145px;
  }

  .partnersPageHeaderDescription {
    width: 100%;
    padding: 0 98px;
  }

  .partnersPageHeaderArc {
    left: -116px;
    right: -116px;
    bottom: -133px;
    height: 183px;
  }

  .partnersContainer {
    padding: 19px 64px 116px;
  }

  .partnerItemsGroup {
    margin-bottom: 100px;
    justify-content: space-around;
  }

  .partnerItem {
    width: 307px;
  }
}

@media screen and (max-width: 767px) {
  .partnersPageHeader {
    padding-top: 48px;
    padding-bottom: 105px;
  }

  .partnersPageHeaderTitle {
    font-size: 36px;
    line-height: 47px;
    padding: 0 68px;
    margin-bottom: 17px;
  }

  .partnersPageHeaderDescription {
    font-size: 14px;
    width: 100%;
    padding: 0 28px;
    margin-bottom: 29px;
  }

  .partnersPageHeaderArc {
    left: -52px;
    right: -52px;
    bottom: -54px;
    height: 95px;
  }

  .partnersContainer {
    padding: 0 28px 80px;
  }

  .partnerItemsGroup {
    margin-bottom: 80px;
    justify-content: center;
  }

  .partnerItem {
    width: 319px;
    max-width: 100%;
  }
}
