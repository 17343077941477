.comparisonPageContainer {
  color: #000;
  font-family: 'roboto_regular';
}

.comparisonHeader {
  margin: 5.75rem 18.75rem 1.5rem;
  text-align: center;
}

.comparisonHeader .headerTitle {
  font-family: 'domainedisplay_regular';
  font-size: 4rem;
  line-height: 5.25rem;
  margin-bottom: 1.5rem;
  margin-left: -8.75rem;
  margin-right: -8.75rem;
}

.headerDescription {
  font-size: 1.125rem;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin-bottom: 1.5rem;
}

.featuresContainer {
  padding: 8.3rem 6.3rem 6rem;
  background-color: #EBF9FF;
  text-align: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.featuresContainer .featuresTitle {
  font-family: 'domainedisplay_regular';
  font-size: 36px;
  line-height: 47px;
  margin-bottom: 1.5rem;
}

.featuresContainer .headerDescription {
  margin-bottom: 5rem;
}

.featuresContainerTopArc {
  position: absolute;
  left: -12.5rem;
  right: -12.5rem;
  top: -10rem;
  height: 14.3rem;
  background-color: #fff;
  border-radius: 50%;
}

.featuresContainer .center-in-row {
  margin-bottom: 3.5rem;
}

.featuresContainer .center-in-row:last-child {
  margin-bottom: 0;
}

.featureContentBlock {
  width: 17.5rem;
  margin-right: 3rem;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.featureContentBlock:last-child {
  margin-right: 0;
}

.featureContentBlock .featureContentTitle {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 134%;
  letter-spacing: 0.015rem;
  margin-bottom: 0.875rem;
}

.featureContentBlock .featureContentDescription {
  flex: 1;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.2px;
  margin-bottom: 1.25rem;
}

.featureContentBlock .featureContentLinkWrapper {
  height: 27px;
}

.featureContentBlock .featureContentLink {
  position: absolute;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 134%;
  letter-spacing: 0.015rem;
  color: #000;
}

.featureContentBlock .featureContentLink:hover {
  text-decoration: none;
}

.comparisonPageContainer .solutionsTitle {
  font-family: 'domainedisplay_regular';
  font-size: 36px;
  line-height: 47px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  margin-top: 3.75rem;
}

.testimonialContainer {
  padding: 3rem 13rem;
  justify-content: center;
  text-align: center;
}

.testimonialContainer h1 {
  font-size: 2rem;
  font-weight: normal;
  line-height: 145%;
  letter-spacing: 0.012rem;
  margin-bottom: 3.5rem;
}

.testimonialContainer .testimonialAvatar {
  width: 5.25rem;
  height: 5.25rem;
  margin-right: 1.5rem;
  border-radius: 50% !important;
  background-color: #C4C4C4;
}

.testimonialContainer .testimonialInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  font-style: normal;
}

.testimonialContainer .testimonialName {
  font-weight: bold;
  padding: 0;
  margin: 0;
  margin-bottom: 6px;
}

.testimonialContainer .testimonialJobTitle {
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.integrationsContainer {
  background-color: #FAFAFA;
  text-align: center;
}

.integrationsPanel {
  width: 100%;
  margin: 5.5rem 18rem;
  padding: 3.5rem 5.25rem 3.75rem;
  background-color: #FFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.integrationsTitle {
  font-family: 'domainedisplay_regular';
  font-size: 28px;
  line-height: 37px;
  margin: 0;
}

.integrationsDescription {
  font-size: 18px;
  line-height: 134%;
  letter-spacing: 0.015rem;
  margin: 2.25rem 0;
}

.integrationsList {
  margin-left: -5.25rem;
  margin-right: -5.25rem;
  margin-bottom: 25px;
}

.integrationsList:last-child {
  margin-bottom: 0;
}

.integrationItem {
  max-width: 14%;
  max-height: 50px;
  margin-right: 35px;
}

.integrationItem:last-child {
  margin-right: 0;
}

.getStartedContainer {
  padding-top: 7rem;
  padding-bottom: 7.75rem;
  position: relative;
}

.getStartedContainer .getStartedTitle {
  font-family: 'domainedisplay_regular';
  font-size: 4rem;
  line-height: 5.25rem;
  width: 100%;
  text-align: center;
  margin-bottom: 3.75rem;
}

.getStartedContainer .actionsContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.getStartedContainer .actionContainer {
  text-align: center;
}

.getStartedContainer .actionContainer .actionBtnWrapper {
  padding: 12px 0;
}

.getStartedContainer .actionContainer .actionBtn {
  padding: 12px 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 5px;
}

.getStartedContainer .actionContainer .actionBtn:hover {
  text-decoration: none;
}

.getStartedContainer .actionContainer:first-child .actionBtn {
  color: #1886DF;
  background-color: #fff;
  border: 1px solid #1886DF;
}

.getStartedContainer .actionContainer:last-child .actionBtn {
  color: #fff;
  background-color: #1886DF;
}

.getStartedContainer .actionContainer .actionName {
  font-family: 'domainedisplay_regular';
  font-size: 2.25rem;
  line-height: 3rem;
  margin-bottom: 2.25rem;
}

.getStartedContainer .actionSeparator {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #000;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 70px;
  text-align: center;
}

.getStartedContainer .stroke {
  position: absolute;
  top: 46px;
  left: 57px;
  width: 120px;
}

.getStartedContainer .line {
  position: absolute;
  bottom: 38px;
  right: 26px;
  width: 244px;
  margin: 0;
}

@media screen and (max-width: 1223px) and (min-width: 768px) {
  .comparisonHeader {
    margin: 3.75rem 3.75rem 3rem;
  }

  .comparisonHeader .headerTitle {
    margin-left: 0;
    margin-right: 0;
  }

  .comparisonHeader .headerDescription {
    margin: 0 5rem 2rem;
  }

  .featuresContainer {
    padding: 8.75rem 6rem 4.25rem;
  }

  .featuresContainerTopArc {
    left: -7.25rem;
    right: -7.25rem;
    top: -14rem;
    height: 19.25rem;
  }

  .featuresContainer .center-in-row {
    justify-content: flex-start;
  }

  .featureContentBlock {
    margin-right: 4.75rem;
  }

  .testimonialContainer {
    padding: 2rem 5rem;
  }

  .integrationsPanel {
    margin: 5.5rem 5rem;
  }

  .integrationsList {
    margin-bottom: 22.5px;
  }
  
  .integrationItem {
    max-height: 45px;
    margin-right: 2rem;
  }

  .getStartedContainer {
    padding-top: 3.5rem;
    padding-bottom: 5.25rem;
  }

  .getStartedContainer .getStartedTitle {
    margin-bottom: 2.5rem;
  }

  .getStartedContainer .actionsContainer {
    flex-direction: column;
  }

  .getStartedContainer .actionSeparator {
    margin: 35px 0;
  }

  .getStartedContainer .stroke {
    top: 131px;
    left: 40px;
    width: 120px;
  }
  
  .getStartedContainer .line {
    bottom: 22px;
    right: 34px;
    width: 226px;
  }
}

@media screen and (max-width: 767px) {
  .comparisonHeader {
    margin: 2.375rem 1.75rem 2.6rem;
  }

  .comparisonHeader .headerTitle {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-left: 0;
    margin-right: 0;
  }

  .comparisonHeader .headerDescription {
    font-size: 0.875rem;
    margin: 0 0.625rem 1.75rem;
  }

  .comparisonHeader .getStartedWrapperWrapper {
    margin: 0 -1.75rem;
  }

  .featuresContainer {
    padding: 5rem 3rem 3.5rem;
  }

  .featuresContainerTopArc {
    left: -3.25rem;
    right: -3.25rem;
    top: -6rem;
    height: 8.25rem;
  }

  .featuresContainer .featuresTitle {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 1rem;
  }
  
  .featuresContainer .headerDescription {
    font-size: 14px;
    margin-bottom: 2rem;
  }

  .featureContentBlock .featureContentDescription {
    font-size: 14px;
    line-height: 135%;
    margin-bottom: 1.375rem;
  }

  .featuresContainer .center-in-row {
    margin-bottom: 2.625rem;
  }

  .featureContentBlock .featureContentLink {
    font-size: 14px;
  }

  .testimonialContainer {
    padding: 1rem 2.25rem;
  }

  .testimonialContainer h1 {
    font-size: 18px;
    margin-bottom: 2.25rem;
  }

  .testimonialContainer .testimonialUser {
    flex-direction: column;
    align-items: center;
  }
  
  .testimonialContainer .testimonialAvatar {
    margin-right: 0;
    margin-bottom: 14px;
  }
  
  .testimonialContainer .testimonialInfo {
    text-align: center;
  }
  
  .testimonialContainer .testimonialName {
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .testimonialContainer .testimonialJobTitle {
    font-size: 12px;
  }

  .integrationsPanel {
    margin: 2.25rem;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .integrationsDescription {
    font-size: 14px;
    margin: 1.75rem 0;
  }

  .integrationsList {
    margin-bottom: 0.75rem;
  }
  
  .integrationItem {
    max-height: 25px;
    margin-right: 1rem;
  }

  .getStartedContainer {
    padding-top: 5rem;
    padding-bottom: 9rem;
  }

  .getStartedContainer .getStartedTitle {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 1rem;
  }

  .getStartedContainer .actionsContainer {
    flex-direction: column;
  }

  .getStartedContainer .actionContainer .actionName {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: 22px;
  }

  .getStartedContainer .actionSeparator {
    margin: 3rem 0;
  }

  .getStartedContainer .stroke {
    top: 215px;
    left: 10px;
    width: 70px;
  }
  
  .getStartedContainer .line {
    bottom: 43px;
    right: 14px;
    width: 230px;
  }
}
