.primary_font {
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif;
  /* font-size: 42px; */
}

.features-img {
  box-shadow:  6px 9px 20px rgba(0,0,0,0.2);
  border-radius: 4px;
}

.featuresPageHeader {
  background-color: #EBF9FF;
  position: relative;
  overflow: hidden;
}

.featuresPageHeader .blueCircle {
  position: absolute;
  right: -1rem;
  top: 1rem;
}

.featuresPageTitle {
  width: 60%;
  margin: 7rem 8rem;
  font-family: 'domainedisplay_regular';
  font-size: 4rem;
  color: #000;
}

.featuresImages {
  width: 100% !important;
  margin: 0 10% !important;
  margin-bottom: 11rem !important;
}

.featureMobileImage {
  flex: 1 !important;
  display: flex;
}

.featureMobileImage img {
  margin-top: auto;
  width: 75%;
  height: auto;
}

.featureDocumentsImage {
  flex: 4 !important;
}

.featureDocumentsImage img {
  width: 100%;
  height: auto;
}

.featuresHeaderBottomArc {
  width: 140%;
  margin-left: -20%;
  margin-right: -20%;
  height: 40%;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  bottom: -32%;
}

.featuresHeaderBlueCircle {
  position: absolute;
  border-radius: 50%;
  border: 5.6px solid #1886DF;
  background: transparent;
  width: 18rem;
  height: 18rem;
  top: 2.5rem;
  right: -5.25rem;
}

@media (max-width: 1223px) and (min-width: 768px) {
  .featuresPageTitle {
    margin: 4rem 4.5rem;
  }

  .featuresImages {
    margin-bottom: 8rem !important;
  }

  .featuresHeaderBlueCircle {
    width: 10rem;
    height: 10rem;
    top: 2rem;
    right: -3rem;
  }
}

@media (max-width: 767px) {
  .featuresPageTitle {
    width: 100%;
    margin: 4rem 2.5rem 2rem;
    font-size: 2.25rem;
  }

  .featureMobileImage {
    display: none;
  }

  .featuresImages {
    margin-bottom: 6rem !important;
  }

  .featuresHeaderBlueCircle {
    width: 5rem;
    height: 5rem;
    top: 1.6rem;
    right: -1.6rem;
  }
}

.titleContainer {
  margin-bottom: 5rem;
  margin-top: 5rem;
  padding: 0 20%;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .titleContainer {
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding: 0 10%;
  }

  .titleContainer h1 {
    font-size: 1.5rem;
  }
}

.features-titleContainer {
  margin-top: 5rem;
  padding: 0 20%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.featuresVideoEmbed {
  justify-content: center;
  margin-bottom: 5rem;
}

.test-row {
  background-color: white;
  /* padding: 0 100px; */
}

.featureImage {
  background-color: #ebf9ff;
  height: auto;
}

.feature-image-left-side-col {
  height: auto;
  min-height: 421px;
  background: linear-gradient(to right, #ebf9ff 50%, transparent 50%);
}

.feature-image-right-side-col {
  height: auto;
  min-height: 421px;
  background: linear-gradient(to left, #ebf9ff 50%, transparent 50%);
}

.feature-image-col {
  background-color: #ebf9ff;
  height: auto;
  min-height: 420px;
}

.featureSubSectionTextAlign {
  display: flex;
  align-items: center;
}

.inner-test-div {
  max-width: 90% !important;
  padding: 10px 0;
}

.featureSendPartnersTitle {
  margin-top: 250px;
  justify-content: center;
  background-color: #ebf9ff;
  /* padding-top: 100px; */
  margin-bottom: 220px;
}

/* .sub_footer_quote {
  color: white;
  margin-top: 50px !important;
  font-size: 3vw !important;
}  */

.sub_footer_quote {
  color: white;
  font-size: 35px;
}

.medium-sub_footer_quote {
  color: white;
  font-size: 30px;
}

.small-sub_footer_quote {
  color: white;
  font-size: 23px;
}

.tiny-sub_footer_quote {
  color: white !important;
  font-size: 20px !important;
}

.tiny-sub-footer-quote-author-credit {
  color: white !important;
  font-size: 15px !important;
  margin-bottom: 0px !important;
}

.extra-tiny-sub_footer_quote {
  color: white !important;
  font-size: 15px !important;
}

.extra-extra-tiny-sub_footer_quote {
  color: white !important;
  font-size: 12.5px !important;
  margin-top: 7px;
}

.extra-tiny-sub-footer-quote-author-credit {
  color: white !important;
  font-size: 12.5px !important;
  margin-bottom: 0px !important;
}

.extra-extra-tiny-sub-footer-quote-author-credit {
  color: white !important;
  font-size: 10px !important;
  margin: 0px !important;
  padding: 0px !important;
}

.tiny-sub_footer_quote-margin {
  margin-top: 10px;
}

.small_sub_footer_quote {
  color: white !important;
  font-size: 20px;
}

.small_sub_footer_quote_author {
  color: white !important;
  font-size: 1.25vw !important;
  line-height: 1.5vw !important;
  padding-bottom: 20px;
}

.subFooterPage {
  justify-content: center;
  height: 20em;
  margin-bottom: 8rem;
  align-items: center;
  display: flex;
}

.subFooterPageQuote {
  align-items: center;
  display: flex;
}

.bullet_point {
  margin-bottom: 10px;
  margin: 0 15px 10px 15px;
}

.researchBottomRow {
  margin-bottom: 200px;
}

.subFooterPageImg {
  width: 50%;
  height: auto;
}

.event-small-subFooterPageImg {
  width: 80%;
  height: auto;
}

.small-subFooterPageImg {
  width: 80%;
  height: auto;
}

.white_font {
  color: white !important;
}
.subFooterPageImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.full-width-dark-blue-custom-height {
  margin: 0 -9999rem;
  /* add back negative margin value */
  padding: 0.25rem 9999rem;
  background: linear-gradient(white 10%, #1786df 10% 90%, white 90%);
}

.features-left-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-top: 0px;
  margin-left: -700px;
}

.feature-homePageFeaturesImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
}

@media (min-width: 1200px) {
  .feature-home-page-shared-component-image-left-side-col {
    height: auto;
    min-height: 421px;
    background: linear-gradient(to right, #ebf9ff 50%, transparent 50%);
  }
  .feature-home-page-shared-component-image-right-side-col {
    height: auto;
    min-height: 421px;
    background: linear-gradient(to left, #ebf9ff 50%, transparent 50%);
  }
}

@media (max-width: 1199px) {
  .feature-home-page-shared-component-image-left-side-col {
    height: auto;
    min-height: 421px;
  }

  .feature-home-page-shared-component-image-right-side-col {
    height: auto;
    min-height: 421px;
  }
}

@media screen and (max-width: 1200px) {
  .feature-homePageFeaturesImage {
    display: flex;
    align-items: center;
    padding: 25px 0 25px 0;
  }
}

@media screen and (max-width: 1000px) {
  .features-left-align-light-blue-background {
    display: none;
  }
  .features-features-right-align-light-blue-background {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .feature-left-blue-background {
    background: linear-gradient(to right, #ebf9ff 50%, transparent 50%);
  }
}

@media screen and (max-width: 1000px) {
  .feature-right-blue-background {
    background: linear-gradient(to left, #ebf9ff 50%, transparent 50%);
  }
}

.features-custom-left-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-left: -800px;
}

.features-right-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-left: 750px;
}

.medium-features-right-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-left: 1450px;
}

.events_small_sub_footer_blurb {
  color: white !important;
  font-size: 15px !important;
  line-height: 17px !important;
  padding-bottom: 10px;
}

.event-small_sub_footer_quote {
  color: white !important;
  font-size: 20px;
}

.feature-send-portal-left-align-light-blue-rectangle {
  position: absolute;
  z-index: 1;
  margin-left: -1200px;
}

.feature-send-portal-right-align-light-blue-rectangle {
  position: absolute;
  z-index: 1;
  margin-left: 0px;
}

.mobile-feature-page-img {
  position: relative;
  width: 80%;
  height: auto;
  z-index: 3;
}

.mobile-feature-page-img-row {
  display: flex;
  justify-content: center;
}

.mobile-features-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 10px 0;
}

.mobile-featuresVideoEmbed {
  justify-content: center;
}

.mobile-featuresFeaturesText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-features-page-content-text {
  margin: 0 15%;
}

.tiny-solutions-sub-footer-container {
  min-width: 300px !important;
}

.vertical-center-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.medium-home-left-side-text-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  max-height: 40px;
}

.productImgRight {
  position: relative;
  margin-right: -200px;
  width: 150%;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  min-width: 500px;
}

.features-productImgRight {
  position: absolute;
  left: 0;
  width: 150%;
  height: auto;
  flex-shrink: 0;
  margin-right: -200px;
  max-width: 500px;
}

.feature-image-sizing {
  max-width: 594px;
  height: auto;
  margin-left: 0px;
}

.medium-feature-subpage-image-sizing {
  max-width: 594px;
  height: auto;
  margin-right: 300px;
}

.medium-feature-home-subpage-image-sizing {
  max-width: 594px; 
  height: auto;
}
.max-width-800{
  max-width: 800px;
}

.feature-subpage-image-sizing-medium{
  max-width: 594px; 
  height: auto;
}

.feature-subpage-image-centering{
  display: flex;
  justify-content: center;
}

.test-feature-subpage-image{
  max-width: 594px;
  height: auto;
}

.medium-feature-subpage-image-sizing-left {
  max-width: 594px;
  height: auto;
  margin-left: 300px;
}

.medium-right-feature-image-sizing {
  max-width: 594px;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  width: 150%;
  height: auto;
}

@media screen and (max-width: 600px) {
  .medium-feature-subpage-image-sizing {
    max-width: 494px;
    height: auto;
  }
}

.features-productImgLeft {
  position: absolute;
  right: 0;
  width: 150%;
  height: auto;
  margin-left: -200px;
  flex-shrink: 0;
}

.subpage-features-productImgLeft {
  position: absolute;
  right: 0;
  width: 150%;
  height: auto;
  margin-left: -200px;
  flex-shrink: 0;
}

.medium-subpage-features-productImg {
  display: flex;
  justify-content: center;
  right: 0;
  width: 150%;
  height: auto;
  flex-shrink: 0;
}

.subpage-features-margin-right {
  padding-right: 100px;
}

@media screen and (max-width: 1000px) {
  .feature-productImgLeft {
    position: relative;
    width: 150%;
    height: auto;
    z-index: 3;
    flex-shrink: 0;
    max-width: 700px;
    margin-left: -125px;
    /* margin-right: 100px; */
  }
  .feature-productImgRight {
    position: relative;
    width: 150%;
    height: auto;
    z-index: 3;
    flex-shrink: 0;
    max-width: 700px;
    margin-right: -125px;
  }

  .featureRows {
    display: flex;
    flex-direction: column;
    margin-bottom: -5rem;
    justify-content: center;
  }

  .subpage-features-productImgLeft {
    position: absolute;
    right: 0;
    width: 500px;
    height: auto;
    /* margin-left: -200px; */
    flex-shrink: 0;
  }
}

.featureRows {
  display: flex;
  justify-content: space-between;
  margin-bottom: -5rem;
}

@media screen and (max-width: 800px) {
  .feature-productImgLeft {
    margin-right: 0px;
    margin-left: 10px;
  }
  .feature-productImgRight {
    margin-left: 0px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 700px) {
  .feature-productImgLeft {
    margin-right: 0px;
    width: 600px;
  }
  .feature-productImgRight {
    margin-left: 0px;
    width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .feature-productImgLeft {
    margin-right: 0px;
    width: 500px;
  }
  .feature-productImgRight {
    margin-left: 0px;
    width: 500px;
  }
}

/* .productImgLeft {
  position: relative;
  margin-left: -200px;
  width: 150%;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  min-width: 500px;
} */

.no-x-axis-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
