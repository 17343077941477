.solutionsPageContainer {
  font-family: 'roboto_regular';
  color: #000;
}

.solutionsPageHeader {
  padding-top: 7.5rem;
  padding-bottom: 3.75rem;
  position: relative;
}

.solutionsPageHeaderTitle {
  font-family: 'domainedisplay_regular';
  font-size: 4rem;
  line-height: 5.25rem;
  margin-bottom: 2.5rem;
}

.solutionsPageHeaderDescription {
  font-size: 18px;
  line-height: 134%;
  letter-spacing: 0.015rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
}

.solutionsPageHeader .img-stroke {
  position: absolute;
  top: 16rem;
  left: 2.25rem;
  width: 2.75rem;
  height: 4.5rem;
}

.solutionsPageHeader .img-circle {
  position: absolute;
  top: 2.5rem;
  right: 3rem;
  width: 5.75rem;
  height: 5.75rem;
}

.solutionsPageHeader .img-line {
  position: absolute;
  bottom: 0.75rem;
  right: 3rem;
  width: 17.5rem;
  height: 6.5rem;
}

.solutionsContentsContainer {
  position: relative;
  padding: 10rem 5rem 4rem;
  background: #EBF9FF;
  overflow: hidden;
}

.solutionsContentsContainerTop {
  position: absolute;
  top: -16.5rem;
  left: -12.5rem;
  right: -12.5rem;
  height: 22.5rem;
  border-radius: 50%;
  background: #fff;
}

.solutionsContentsHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 70px !important;
}

.solutionsContentsHeader a {
  padding: 0.75rem 1rem;
  margin-right: 3.75rem;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  color: #676767;
}
.solutionsContentsHeader a:last-child {
  margin-right: 0;
}
.solutionsContentsHeader a:hover {
  color: #1886DF;
  margin-bottom: 0 !important;
}
.solutionsContentsHeader a.active {
  border-radius: 5px;
  background: #1886DF !important;
}
.solutionsContentsHeader a::before {
  display: none;
}

.solutionsContentItemsRow {
  margin-bottom: 5.5rem;
}

.solutionsContentItemsRow:last-child {
  margin-bottom: 0;
}

.solutionsContentItem {
  margin-right: 50px;
  font-family: 'Roboto';
  color: #000;
}

.solutionsContentItem:last-child {
  margin-right: 0;
}

.solutionsContentItemTitle {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin-bottom: 0.75rem;
}

.solutionsContentItemDescription {
  font-size: 1rem;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin: 0;
}

.solutionsContentItemLink {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 1rem;
  line-height: 134%;
  letter-spacing: 0.015em;
  color: #000;
  margin-top: 1.5rem;
}

.solutionsContentsExploreAllBtn {
  background: #FF6575;
  border-radius: 5px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  color: #fff;
  text-align: center;
  padding: 22px 36px;
  margin-top: 4.625rem;
}

.solutionsContentsExploreAllBtn:hover {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.solutionsPageContainer .marqueeText {
  font-family: 'domainedisplay_regular';
  font-size: 3rem;
  line-height: 3.75rem;
  color: #2B1F30;
  margin-top: 7rem;
}

@media screen and (max-width: 1223px) and (min-width: 768px) {
  .solutionsPageHeader {
    padding-top: 9rem;
    padding-bottom: 6rem;
  }

  .solutionsPageHeaderTitle {
    margin-bottom: 1.5rem;
  }

  .solutionsPageHeader .img-stroke {
    top: inherit;
    bottom: 3.5rem;
  }
  
  .solutionsPageHeader .img-circle {
    top: 1.75rem;
    right: -2rem;
  }
  
  .solutionsPageHeader .img-line {
    bottom: 2.75rem;
    right: 1.75rem;
    width: 10.75rem;
    height: 4rem;
  }

  .solutionsContentsContainer {
    padding: 6.75rem 3.5rem 5.25rem;
  }

  .solutionsContentsContainerTop {
    top: -11.25rem;
    left: -7.25rem;
    right: -7.25rem;
    height: 15.5rem;
  }
  
  .solutionsContentsHeader {
    justify-content: space-between;
    margin-bottom: 53px !important;
  }
  
  .solutionsContentsHeader a {
    margin-right: 0;
  }

  .solutionsContentItemsRow {
    margin-bottom: 4.25rem;
  }
  
  .solutionsContentItem {
    margin-right: 5rem;
  }

  .solutionsContentsExploreAllBtn {
    margin-top: 6rem;
  }

  .solutionsPageContainer .marqueeText {
    margin-top: 4.75rem;
    margin-left: 7rem;
    margin-right: 7rem;
  }
}

@media screen and (max-width: 767px) {
  .solutionsPageHeader {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
  }

  .solutionsPageHeaderTitle {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-left: 4.25rem;
    margin-right: 4.25rem;
    margin-bottom: 2.25rem;
  }

  .solutionsPageHeaderDescription {
    font-size: 14px;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    margin-bottom: 2.25rem;
  }

  .solutionsPageHeader .img-stroke,
  .solutionsPageHeader .img-circle,
  .solutionsPageHeader .img-line {
    display: none;
  }

  .solutionsContentsContainer {
    padding: 6.25rem 2.5rem 4.25rem;
  }

  .solutionsContentsContainerTop {
    top: -9.5rem;
    left: -3.25rem;
    right: -3.25rem;
    height: 13rem;
  }
  
  .solutionsContentsHeader {
    justify-content: space-evenly;
    margin-bottom: 66px !important;
    flex-flow: row wrap;
  }

  .solutionsContentsHeader::before, .solutionsContentsHeader::after {
    content: '';
    width: 100%;
    order: 1;
  }
  
  .solutionsContentsHeader a {
    font-size: 14px;
    line-height: 1rem;
    margin-right: 0;
  }

  .solutionsContentsHeader a:nth-child(n+3) {
    margin-top: 12px;
    order: 1;
  }

  .solutionsContentItemsRow {
    margin-bottom: 30px
  }

  .solutionsContentItemTitle {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .solutionsContentItemDescription {
    font-size: 14px;
    line-height: 18px;
  }

  .solutionsContentItemLink {
    font-size: 14px;
    margin-top: 20px;
  }

  .solutionsContentsExploreAllBtn {
    width: 100%;
    margin-top: 3.5rem;
  }
}
