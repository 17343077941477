.comparisonItem {
  font-family: 'roboto_regular';
  padding: 9rem 7rem;
}

.comparisonItem .itemOrder {
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 28px;
  letter-spacing: 1.3px;
  font-variant: small-caps;
}

.comparisonItem .itemTitle {
  width: 100%;
  font-family: 'domainedisplay_regular';
  font-size: 2.25rem;
  line-height: 3rem;
  margin: 0;
  margin-bottom: 1rem;
}

.comparisonItem .itemDescription {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin: 0;
  margin-bottom: 2rem;
}

.comparisonItem .itemLink {
  font-weight: 500;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 134%;
  letter-spacing: 0.015em;
  color: #000;
  border-bottom: 1px solid #ff6574;
}

.comparisonItem .itemLink:after {
  border-bottom: 3px solid #ff6574;
  transition: width 0.3s;
  margin-bottom: -3px;
  content: "";
  display: block;
  width: 0;
  height: 2px;
}

.comparisonItem .itemLink:hover {
  text-decoration: none;
}

.comparisonItem .itemLink:hover::after {
  width: 100%;
}

.comparisonItemImageWrapper {
  margin-left: 0;
  margin-right: 5rem;
}

.col + .comparisonItemImageWrapper {
  margin-left: 5rem;
  margin-right: 0;
}

@media screen and (max-width: 1223px) and (min-width: 768px) {
  .comparisonItem {
    padding: 4rem 5rem;
    display: block !important;
  }

  .comparisonItemImageWrapper {
    max-width: 700px !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  .comparisonItem {
    padding: 3.5625rem 2.375rem;
    display: block !important;
  }

  .comparisonItem .itemTitle {
    font-size: 1.75rem;
    line-height: 2.375rem;
  }

  .comparisonItem .itemDescription {
    font-size: 0.875rem;
    margin-bottom: 2.25rem;
  }

  .comparisonItem .itemLink {
    font-weight: bold;
    font-size: 0.875rem;
  }

  .comparisonItemImageWrapper {
    margin-bottom: 2.8rem;
  }
}