.feature-content-container {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: transparent;
}

.feature-content-row {
  display:flex;
  justify-content: center;
  padding-bottom:50px;

}

.feature-content-block {
  border: 0px solid black;
  display: flex;
  justify-content: center;
}


.feature-content-block-container {
  margin-left: 12px;
  margin-right: 12px;
}

@media (max-width: 767px) {
  .feature-content-block h4 {
    font-size: 18px;
  }
  .feature-content-block p,
  .feature-content-block a {
    font-size: 14px;
  }
}
