.secondary_font_bold {
  font-family: "Lato roboto_regular";
  font-style: medium;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
}

.aboutPageHeaderText {
  margin-right: 100px;
}

.small-eventPageTitle {
  margin-top: 100px;
  align-content: center;
}

.aboutPageTitle {
  margin-bottom: 175px;
  margin-top: 100px;
  align-content: center;
}

.small-aboutPageTitle {
  align-content: center;
}

.byTheNumbersObject {
  align-content: center;
  justify-content: center;
}

.byTheNumbersObjectContent {
  align-content: center;
  justify-content: center;
  text-align: center;
}

.who-we-are-image-container {
  justify-content: flex-end;
  padding-top: 5%;
  padding-bottom: 5%;
}

.small-who-we-are-image-container {
  justify-content: center;
}

.mobile-who-we-are-image-container {
  display: block;
}

.mobile-about-page-who-we-are-image {
  width: 80%;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 37.5px;
}

.about-page-who-we-are-image {
  /* max-width: 50%; */
  height: auto;
  margin-left: 50px;
  width: 350px;
}

.aboutPageWhoWeAreContent {
  margin: 0 20px 0 20px;
  color: white;
}

.mobile-aboutPageWhoWeAreContent {
  margin: 0 30px;
  color: white;
  padding-bottom: 20px;
  text-align: center;
}

.aboutPageWhoWeAreContentContainer {
  display: flex;
  align-items: center;
}

.by-the-numbers {
  justify-content: center;
  padding-bottom: 5%;
}

.byTheNumbersObjectContainer {
  height: 150px;
  padding-bottom: 150px;
  padding-top: 50px;
}

.aboutPageNumbersObjectSubtitle {
  margin: 0 20px 0 20px;
}

.aboutPageLeadershipTeam {
  display: flex;
  flex-wrap: wrap;
}

.leaderImage {
  display: flex;
  margin-bottom: 30px;
  flex-shrink: 2;
  margin-left: 10px;
  margin-right: 10px;
  width: 247px;
  min-width: 247px;
  height: 370px;
}

.oddNumberLeaderImage {
  clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 98%);
}

.evenNumberLeaderImage {
  clip-path: polygon(0 0, 100% 2%, 100% 98%, 0 100%);
}

.leadership-team-container {
  margin: 15px 10px !important;
}

.leadershipObjectContainer {
  height: 150px;
  padding-bottom: 150px;
  padding-top: 50px;
  margin-bottom: 10px;
  max-width: 300px;
}

.leadershipObjectContent {
  justify-content: center;
  text-align: center;
}

.partnersTitle {
  padding: 0 10% 0 10%;
  text-align: center;
}

.aboutPagePartnersImage {
  display: flex;
  justify-content: space-around;
  max-width: 200px;
  width: 100%;
  height: auto;
}

.mobile-homePartnerImagesContainer {
  display: flex;
}
/*
.mobile-aboutPartnerImages {
  width: 50%;
  height: auto;
  margin-bottom: 30px;
} */

.mobile-about-partners-container {
  border: solid red 2px;
}

.mobile-aboutPartnerImagesRow {
  border: solid blue 2px;
}

.aboutPartners {
  justify-content: center;
}

.counselor-advisor-container {
  margin: 15px 5px;
  /* min-height: 620px; */
  display: flex;
  justify-content: center;
}

.counselor-advisor-container-inner-object {
  padding: 20px;
  font-size: 20px;
}

/* WORK FROM HERE */

.aboutPageCounselorTitle {
  margin: auto;
}

.counselors {
  margin-bottom: 150px;
}

.advisorObject {
  background-color: white;
  margin: 15px 30px;
}

.centeredText {
  text-align: center;
}

.officesContainer {
  background-color: #2b1f31;
  color: white;
  height: 375px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}

.officeImgContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 200px;
}

.office-image {
  align-self: center;
}

.offices_row {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 475px;
}
.officeTitle {
  color: white;
}

.office-text-container {
  height: 140px;
}

.white-text {
  color: white !important;
}

.mobile-hero-image-container {
  display: flex;
  justify-content: center;
}

.mobile-header {
  border: solid blue 2px;
}

.mobile-hero-image {
  width: 105%;
  height: auto;
}

.mobile-top-row-header-container {
  padding: 0 20px;
}

.reduced-margin-bottom {
  margin-bottom: 0px;
}

.about-partners-columns {
  margin: 0 75px;
}

.by-the-numbers-object-image {
  margin-bottom: 15px;
}

.about-page-counselor-advisor-header {
  margin-top: 3rem;
}

.about-page-counselor-advisor-footer {
  margin-bottom: 100px;
}

.counselor-test {
  background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
}

/* .counselor-advisor-blurb {
  min-height: 65%;
} */

.about-leadership-team-row {
  justify-content: space-around;
}

.linked-in-icon {
  max-width: 20px;
}

.biz-linked-in-icon {
  max-width: 60px;
  border-radius: 5px;
  border: solid #fefefe 1px;
}

.linked-in-container {
  display: flex;
  justify-content: center;
}

.about-biz-image-div {
  display: grid;
  grid-template-columns: 100px 100px;
  min-width: 250px;
  width: 250px;
}

.about-biz-image-col {
  align-self: center;
  justify-self: start;
}

.about-biz-title-col {
  align-self: center;
  justify-self: start;
}
