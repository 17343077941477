.ZoomPageHeader {
  background-image: url('../../img/zoom/Zoom-Meeting-Hero.jpg');
  width: 100%;
  height: 424px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 32px;
  padding-left: 204px;
}

.ZoomPageHeader.header-md {
  padding-left: 100px;
  background-size: contain;
}

.mobile-background {
  background-image: url('../../img/zoom/background_mobile.png');
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.header-sm {
  margin-top: 46px;
}

.ZoomPageMain {
  margin-top: 20px;
}

.ZoomPageMain.sm {
  margin-top: 79px;
}

.ZoomPageMainContainer {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.ZoomCialfoImg {
  height: 50px;
}

.ZoomCialfoImg.sm {
  width: 300px;
  height: 40px;
}

.form-sm-container {
  margin-top: 33px;
  width: 346px;
}

.title-width {
  width: 500px;
}

.text-small {
  width: 347px;
}

.btn-sign-in {
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  color: #FFF;
  padding: 0 18px;
  background: #1886DF;
  border-radius: 0.25rem;
  border: none;
  width: 350px;
  padding-bottom: 12px;
  padding-top: 12px;
}

.btn-sign-in.sm {
  width: 254px;
}

.input {
  border: 1px solid #979797 !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right-style: none !important;
  height: 43px !important;
}

.input-label {
  background-color: #F8F8F8;
  border: 1px solid #979797;
  color: #979797;
  padding: 6px 12px;
  margin-right: 12px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  align-items: center;
}

.input-label.sm {
  margin-right: 0px;;
}

.main-text-p {
  line-height: 2;
}

.main-text-p.sm {
  line-height: 1.786;
  text-align: center;
  font-size: 14px;
  color: #000000;
}

.main-text-row.sm {
  margin-top: 43px;
  margin-bottom: 51px;
}

.text-sm {
  font-size: 14px;
  line-height: 1.643;
  color: #000000;
}

.text-black {
  color: #000000;
}

.font-18 {
  font-size: 18px;
}

.mb-11px {
  margin-bottom: 11px;
}