.getStartedWrapper .email {
  width: 250px;
  max-width: 250px;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #878787;
  border: 0.5px solid #B1B1B1;
  border-radius: 5px;
  padding: 15px;
  margin-right: 0.5rem;
}

.getStartedWrapper .getStarted {
  font-weight: bold;
  font-size: 1rem;
  line-height: 19px;
  color: #FFF;
  padding: 0 18px;
  background: #1886DF;
  border-radius: 5px;
  border: none;
}

@media screen and (max-width: 767px) {
  .getStartedWrapper {
    width: 100%;
    padding: 0 30px;
  }

  .getStartedWrapper .email {
    flex: 1;
    padding: 0.75rem;
  }

  .getStartedWrapper .getStarted {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}