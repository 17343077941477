.logo-text {
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif;
  padding-left: 10px;
}

.header {
  background-color: white;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.nav-link-button {
  font-family: "roboto_regular";
  font-size: 16px !important;
  margin-bottom: 6px;
  margin-left: 8px !important;
  background-color: #1886df !important;
}

.nav-link-button:hover {
  background-color: #0069d9 !important;
}

.navbar-brand {
  font-family: "roboto_regular";
}

.navbar-nav .nav-link {
  color: #2b1f30 !important;
  font-family: "roboto_regular";
  position: relative;
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1886df;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.nav-link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.nav-link:hover {
  display: inline-block;
  border-bottom-color: #1886df;
  margin-bottom: -3px !important;
  padding-bottom: 0px !important;
}

.nav-link:after {
  content: "";
  width: 0;
  height: 11px;
}

.nav-link:hover::after {
  width: 100%;
  display: block;
}

.footer-background {
  background-color: #ebf9ff;
}

.footer {
  padding: 15px 0 !important;
}

.test {
  margin-bottom: 100px;
}

.translator:hover {
  cursor: pointer;
}

@media (max-width: 767px) {
  .nav-link-button {
    margin-top: 14px;
  }
}

.demo-page-link {
  color: #1886df !important;
}

.activeStyle {
  border-bottom: solid 2px #1886df !important;
}

.mobile-activeStyle {
  font-weight: bold;
}

.footer-nav-link {
  padding: 0px !important;
  display: inline-block;
}

.blue-header-locale-text {
  margin-left: -15px;
  margin-top: 5px;
  color: #1886df !important;
  font-size: 12px;
}

.mobile-blue-header-locale-text {
  margin-left: -150px;
  margin-top: -30px;
  color: #1886df !important;
  font-size: 12px;
}

@media (max-width: 850px) {
  .footer-logo {
    display: none;
  }
}

.navbar-nav {
  align-items: flex-end !important;
}

.navbar-toggler {
  border: none !important;
}

.logo {
  max-width: 128px !important;
  height: auto;
}

.navbar-nav .footer-nav-link {
  color: #2b1f30 !important;
  font-family: "roboto_regular";
  position: relative;
}

.footer-nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #1886df;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.footer-nav-link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.footer-nav-link:hover {
  display: inline-block;
  border-bottom-color: #1886df;
  margin-bottom: -3px !important;
  padding-bottom: 0px !important;
  text-decoration: none !important;
}

.nav-link:after {
  content: "";
  width: 0;
  height: 11px;
}

.nav-link:hover::after {
  width: 100%;
  display: block;
}
