.events-header-text {
  margin-bottom: 100px;
}

.events-subtitle p {
  width: 600px;
  text-align: center;
}

.events-subfooter-page {
  justify-content: center;
  height: 20em;
  margin-bottom: 150px;
  align-items: center;
  display: flex;
}

.event-link {
  text-decoration: none;
  color: inherit;
  border-bottom: #1886df 2px solid;
  padding-bottom: 2px;
  font-weight: bold;
}

.event-link:hover {
  text-decoration: none;
  color: inherit;
  border-bottom: #1886df 2px solid;
  padding-bottom: 2px;
  font-weight: bold;
}

.event-bold {
  font-weight: bold !important;
}

.event-remove-margin-bottom {
  margin-bottom: inherit;
}

.event-inner-object {
  padding: 50px 40px;
}

.events-blurb {
  padding-bottom: 10px;
  padding-top: 15px;
}

.events-partners-title {
  padding: 0 10% 0 10%;
  text-align: center;
}

.events-blue-stroke-10 {
  position: absolute;
  z-index: 1;
  margin-top: -300px;
  margin-left: 1050px;
}

.events-oval {
  position: absolute;
  z-index: 1;
  margin-top: -275px;
  margin-left: -120px;
}

.events-cialfo-events-sessions-bottom-row {
  padding-bottom: 100px;
}

.events-cialfo-events-sessions-top-row {
  padding-top: 100px;
}

.mobile-events-hero-image {
  width: auto;
  height: 250px;
}

.mobile-events-header-text {
  text-align: center;
}

.mobile-events-bottom-border {
  border-bottom: solid #f5f5f5 1px;
}

.mobile-events-top-border {
  border-top: solid #f5f5f5 1px;
}

.full-screen-events-clock {
  padding-top: 4px;
  width: 14px;
  height: auto;
}

.mobile-events-clock {
  padding-top: 4px;
  width: 14px;
  height: auto;
}

.events-clock-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
