.primary_font {
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif;
}

.medium-home-feature-img-div-container{
  display: inline-flex;
  align-content: center;
  justify-content: center;
}

.medium-home-feature-img-div{
  display: inline-flex;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.secondary_font {
  font-family: "roboto_regular";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

.secondary_font_regular {
  font-family: "roboto_regular";
  font-style: medium;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

.top_row {
  display: flex;
  justify-content: space-around;
  margin: 5rem 0 5rem 0;
}

.mobile_top_row {
  display: flex;
  justify-content: center;
  margin: 30px 0 30px 0;
  border-bottom: solid #d1d3d4 1px;
}

.mobile-bottom-border {
  border-bottom: solid #f5f5f5 1px;
}

.mobile-top-border {
  border-top: solid #f5f5f5 1px;
}

.mobile_top_row_left_col {
  display: flex;
  align-items: flex-end;
  margin: 0 100px;
}

.mobile_top_row_header {
  text-align: center;
  width: 250px;
}

.no-border {
  border: 0;
  box-shadow: none;
}

.homePageFeaturesSendDocumentLinkText {
  border-bottom: 1px solid #1986df;
  padding-bottom: 1px;
}

.mobile-homePageFeaturesSendDocumentLinkText {
  border-bottom: 1px solid #1986df;
  padding-bottom: 1px;
}

.mobile-homePageFeaturesSendDocumentLinkText:hover {
  border-bottom: 3px solid #1986df;
  margin-bottom: -2px;
}

.homePageFeaturesSendDocumentLinkText:after {
  border-bottom: 3px solid #1986df;
  transition: width 0.3s;
  margin-bottom: -3px;
  content: "";
  display: block;
  width: 0;
  height: 2px;
}

.homePageFeaturesSendDocumentLinkText:hover::after {
  width: 100%;
}

.homePageFeaturesLeverageLinkText {
  border-bottom: 1px solid #ff6574;
  padding-bottom: 1px;
}

.homePageFeaturesLeverageLinkText:after {
  border-bottom: 3px solid #ff6574;
  transition: width 0.3s;
  margin-bottom: -3px;
  content: "";
  display: block;
  width: 0;
  height: 2px;
}

.homePageFeaturesLeverageLinkText:hover::after {
  width: 100%;
}

.homePageFeaturesDiscoverLinkText {
  border-bottom: 1px solid #ffca43;
  padding-bottom: 1px;
  font-weight: bold;
}

.homePageFeaturesDiscoverLinkText:after {
  border-bottom: 3px solid #ffca43;
  transition: width 0.3s;
  margin-bottom: -3px;
  content: "";
  display: block;
  width: 0;
  height: 2px;
}

.homePageFeaturesDiscoverLinkText:hover::after {
  width: 100%;
}

.homePageHeaderEmailSubmitButton {
  align-self: center;
  border: green solid 2px;
}

.homePageFeaturesImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
}

.home-homePageFeaturesImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
}

@media screen and (max-width: 1200px) {
  .home-homePageFeaturesImage {
    display: flex;
    align-items: center;
    padding: 25px 0 25px 0;
  }
}
@media screen and (max-width: 1200px) {
  .home-left-blue-background {
    background: linear-gradient(to right, #ebf9ff 50%, transparent 50%);
  }
}

@media screen and (max-width: 1200px) {
  .home-right-blue-background {
    background: linear-gradient(to left, #ebf9ff 50%, transparent 50%);
  }
}

.mobile-homePageFeaturesImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 10px 0;
}

.mobile-home-page-video-case-study-title {
  padding: 0 20px;
}

.homePageFeaturesText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-homePageFeaturesText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homePageFeaturesRightSideTextObject {
  margin: 0 15%;
}

.mobile-homePageFeaturesRightSideTextObject {
  padding: 0 0 0 35px;
  max-width: 350px;
  display: flex;
  justify-content: center;
}

.homePageFeaturesLeftSideTextObject {
  margin: 0 10%;
}

/* .homePageFeaturesImageBackground {
  background: linear-gradient(90deg, #ebf9ff 50%, white 50%);
} */

/* .homePageFeaturesImageBackgroundReverse {
  background: linear-gradient(90deg, white 50%, #ebf9ff 50%);
} */

.homeMarquee {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.featureRows {
  display: flex;
  justify-content: space-between;
  margin-bottom: -5rem;
}

.homeRows {
  display: flex;
  justify-content: space-between;
  margin-bottom: -5rem;
}

@media screen and (max-width: 1200px) {
  .homeRows {
    display: flex;
    flex-direction: column;
    margin-bottom: -5rem;
    justify-content: center;
  }
}

.mobile-featureRows {
  display: flex;
  justify-content: center;
  margin-bottom: 125px;
}

.homePageVideoCaseStudyTitle {
  text-align: center;
  justify-content: center;
  margin-bottom: 50px;
  padding: 5rem 5rem 0px 5rem;
}

.mobile-homePageVideoCaseStudyTitle {
  text-align: center;
  justify-content: center;
  padding: 10px;
}

.homePageVideoCaseStudy {
  background: linear-gradient(#1886df 70%, white 30%);
  min-width: 100%;
}

.mobile-homePageVideoCaseStudy {
  min-width: 100%;
  background: #1886df;
}

.homePageVideoCaseStudyVideoEmbed {
  justify-content: center;
  margin-bottom: 5rem;
}

.mobile-homePageVideoCaseStudyVideoEmbed {
  justify-content: center;
}

.homePagePoweredByOurPartnersTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

.homePageHeaderProductImage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.homePartnerImages {
  height: auto;
  width: 250px;
  margin: auto;
}

.mobile-homePartnerImagesContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100px;
  width: auto;
}

.mobile-homePartnerImages {
  display: flex;
  justify-content: center;
  align-content: center;
  height: auto;
  width: 70%;
  margin-bottom: 30px;
}

.mobile-imageObjectCol {
  justify-content: center;
}

.imageObjectTitle {
  display: flex;
  justify-content: center;
}

.mobile-imageObjectTitle {
  margin-bottom: 10px;
  padding: 10px;
}

.mobile-imageObjectTitleText {
  text-align: center;
}

.mobile-homePageImg {
  position: relative;
  width: 80%;
  height: auto;
  z-index: 3;
}

.mobile-homePageImg-row {
  display: flex;
  justify-content: center;
}

.homePageImg {
  position: relative;
  width: 160%;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  min-width: 500px;
}

.productImgRight {
  position: relative;
  margin-right: -200px;
  width: 150%;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  min-width: 500px;
}

.home-productImgRight {
  position: relative;
  margin-right: -200px;
  width: 150% !important;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  min-width: 500px;
}

@media screen and (max-width: 1200px) {
  .home-productImgRight {
    position: relative;
    width: 150%;
    height: auto;
    z-index: 3;
    flex-shrink: 0;
    max-width: 700px;
    margin-left: 100px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 800px) {
  .home-productImgRight {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 700px) {
  .home-productImgRight {
    margin-right: 0px;
    max-width: 600px;
  }
}

.productImgLeft {
  position: relative;
  margin-left: -200px;
  width: 150%;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  min-width: 500px;
}

.home-productImgLeft {
  position: relative;
  margin-left: -200px;
  width: 150%;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  min-width: 500px;
}

@media screen and (max-width: 1200px) {
  .home-productImgLeft {
    position: relative;
    width: 150%;
    height: auto;
    z-index: 3;
    flex-shrink: 0;
    max-width: 700px;
    margin-left: 0px;
    margin-right: 100px;
  }
}

@media screen and (max-width: 800px) {
  .home-productImgLeft {
    margin-right: 0px;
  }
}

@media screen and (max-width: 700px) {
  .home-productImgLeft {
    margin-right: 0px;
    max-width: 600px;
  }
}

.small-homePageImg {
  position: relative;
  width: 80%;
  height: auto;
  z-index: 3;
  flex-shrink: 0;
  max-width: 1000px;
  /* min-width: 500px; */
}

.stroke-10 {
  position: absolute;
  z-index: 2;
  margin-top: -200px;
  margin-left: -270px;
}

.left-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-top: -100px;
  margin-left: -1200px;
}

.custom-left-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-left: -800px;
}

.home-custom-left-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-left: -800px;
}

@media screen and (max-width: 1200px) {
  .home-custom-left-align-light-blue-background {
    /* position: absolute;
    z-index: 1;
    margin-left: 0px; */
    display: none;
  }
}

.home-right-align-light-blue-background {
  position: absolute;
  z-index: 1;
  margin-left: 750px;
}

@media screen and (max-width: 1200px) {
  .home-right-align-light-blue-background {
    /* position: absolute;
    z-index: 1;
    margin-left: 0px; */
    display: none;
  }
}

.features-hero-image {
  position: relative;
  display: flex;
  height: auto;
  z-index: 3;
}

.mobile-features-hero-image {
  position: relative;
  display: flex;
  height: auto;
  z-index: 3;
  width: 300px;
}

.get-a-demo-button {
  font-size: 1.1em !important;
  background: none;
  color: white !important;
  border: none;
  border-bottom: 2px solid #f4f4f5;
  padding-bottom: 3.6px;
  cursor: pointer;
  outline: inherit;
  margin-top: 10px;
  min-width: 110px;
}

.get-a-demo-link {
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif;
  font-size: 1.1em !important;
  background: none;
  color: white !important;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  margin-top: 10px;
}

.get-a-demo-link:hover {
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif;
  font-size: 1.1em !important;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  margin-top: 10px;
  text-decoration: none !important;
}

.get-a-demo-button:hover {
  font-size: 1.1em !important;
  background: none;
  /* color: #757575 !important; */
  border-bottom: 2px solid #1981d6;
  padding-bottom: 3.6px;
  cursor: pointer;
  outline: inherit;
  margin-top: 10px;
  text-decoration: none !important;
}

.homeFeatureLink {
  color: #2b1f30 !important;
  font-weight: bold;
}

.homeFeatureLink:hover {
  text-decoration: none;
}

.white-font {
  color: white !important;
}

.get-a-demo-form {
  min-width: 470px;
}

.home-partners-image-container {
  display: flex;
}

.home-partners-image-content {
  margin: 50px;
}

.home-partners-image-content-auto-margin {
  margin-left: auto;
  margin-right: auto;
}

.demo-cta-container {
  padding: 50px;
  background: #1886DF;
}

.demo-cta-container .submit_button {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2B1F30;
  background: #FFCA44 !important;
  border-radius: 5px !important;
  border: none;
  width: inherit !important;
  padding: 0 42px !important;
}

.demo-cta-container .submit_button:hover {
  color: #2B1F30 ;
}

.demo-cta-title-text {
  font-size: 4rem;
  text-align: center;
  width: 930px;
}

.demo-cta-content {
  display: flex;
  justify-content: center;
  /* padding-bottom: 0px; */
}

@media (max-width: 1223px) and (min-width: 768px) {
  .demo-cta-title-text {
    width: 660px;
  }
}

@media (max-width: 767px) {
  .demo-cta-title-text {
    font-size: 2.25rem;
    width: 100%;
  }
}

.home-page-partner-image {
  margin: 0 10px;
}

.partner-object-image-container {
  display: flex;
  height: 100px;
}

.edi-title-text {
  width: 500px;
}

.downloads-oval {
  margin-top: 200px;
  margin-left: -650px;
  position: absolute;
  z-index: 0;
}

.downloads-yellow-stroke-10 {
  margin-top: 200px;
  margin-left: 475px;
  position: absolute;
  z-index: 0;
}

.downloads-qr-code {
  z-index: 1;
}

.downloads-full-width-dark-blue {
  margin: 0 -9999rem;
  padding: 0.25rem 9999rem;
  background: #1786df;
}

.home-page-button {
  font-size: 16px !important;
  margin-top: 8px !important;
  background-color: #1886df !important;
  font-family: "domainedisplay_regular", Arial, Helvetica, sans-serif !important;
}

.home-page-button:hover {
  background-color: #0069d9 !important;
}

.home-page-button-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.home-medium-header-content {
  max-width: 900px;
}

@media screen and (max-width: 920px) {
  .home-medium-header-content {
    max-width: 700px;
  }
}

@media screen and (max-width: 920px) {
  .home-medium-header-content {
    max-width: 700px;
    padding: 0 50px;
  }
}

.marquee-school-image-container{
  display: flex;
  justify-content: flex-start;
}

.marquee-school-image{
  max-height: 60px;
  width: auto;
}

.slider-container{
  /* border: solid red 2px; */
  justify-content: center;
}

.partial-width-dark-blue {
  margin: 0 -9999rem;
  /* add back negative margin value */
  padding: 0.25rem 9999rem;
  background: #1786df;
  /* background: linear-gradient (#1786df 55%, white 55%); */
  background: linear-gradient(white 3%, #1786df 3% 55%, white 55%);
}

.slick-current{
  filter: none !important;
}

.slick-slide{
  filter: blur(2.5px);
}

@media screen and (max-width: 500px) {
  .slick-list{
    max-height: 200px;
    max-width: 1000px;
  }
  .slick-track{
    max-width: 100vw;
  }
  .slick-slide{
    max-width: 200px !important;
  }
}


@media screen and (max-width: 500px) {
  .home-page-carousel{
    display: none ;
  }

}

@media screen and (min-width: 501px) {
  .home-page-video{
    display: none;
  }
}