.solutionsSubPageHeader {
  padding-top: 6.25rem;
  padding-bottom: 8rem;
  align-items: center;
  flex-direction: column;
  color: #000;
  font-family: 'Roboto';
  position: relative;
}

.solutionsSubPageHeaderTag {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.75rem;
  letter-spacing: 0.2px;
  color: #1886DF;

  border-radius: 3px;
  background: #EBF9FF;
  width: 7.75rem;
  height: 1.75rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.solutionsSubPageHeaderTitle {
  font-family: 'domainedisplay_regular';
  font-size: 4rem;
  line-height: 5.25rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.solutionsSubPageHeaderDescription {
  font-size: 18px;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin-bottom: 1.5rem;
  text-align: center;
}

.solutionsUsageTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-bottom: 1.875rem;
}

.solutionsUsageItemsGroup {
  margin-bottom: 30px;
}

.solutionsUsageItems {
  margin-right: 6rem;
}

.solutionsUsageItems:last-child {
  margin-right: 0;
}

.solutionsUsageItem {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 1.3px;
  font-variant: small-caps;
  color: #2B1F30;
}

.solutionsUsageItem::before {
  content: '';
  font-family: 'FontAwesome';
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-right: 10px;
}

.solutionsUsageItem:last-child {
  margin-right: 0;
}

.solutionsSubPageHeader .getStarted {
  background: #1886DF;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 12px 50px;
}

.solutionsSubPageHeader img.stroke {
  position: absolute;
  width: 175px;
  top: 24px;
  right: 0;
}

.solutionsSubPageHeader img.line {
  position: absolute;
  width: 282px;
  bottom: 24px;
  left: -46px;
  margin: 0;
  padding: 0;
}

.solutionItem {
  padding: 0 115px;
  margin-bottom: 140px;
  color: #000;
}

.solutionItem.featured {
  background: #EBF9FF;
  padding-top: 116px;
  padding-bottom: 83px;
}

.solutionItem .rtl {
  direction: rtl;
}

.solutionItemInfo {
  flex: 1;
  margin-right: 56px;
}

.solutionItemImg {
  width: 50%;
}

.solutionItemTag {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1.3px;
  font-variant: small-caps;
  margin: 0;
}

.solutionItemTitle {
  font-family: 'domainedisplay_regular';
  font-size: 36px;
  line-height: 47px;
  margin-bottom: 16px;
}

.solutionItemDescription {
  font-size: 18px;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin: 0;
}

.solutionItemTitle + .solutionItemDescription {
  margin-bottom: 25px;
}

.solutionItemSubItem {
  margin-bottom: 15px;
}

.solutionItemSubItem:last-child {
  margin-bottom: 0;
}

.solutionItemSubItemTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 134%;
  letter-spacing: 0.015em;
  margin-bottom: 10px;
}

.solutionItemPartnersPanel {
  width: 100%;
  margin-top: 58px;
}

.solutionItemPartnersPanelTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 60px;
  color: #676767;
  text-align: center;
  margin-bottom: 9px;
}

.solutionItemPartnersGroup {
  display: flex;
  justify-content: center;
}

.solutionItemPartner {
  width: 200px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 30px;
}

.solutionItemPartner:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1223px) and (min-width: 768px) {
  .solutionsSubPageHeader {
    padding-top: 4.75rem;;
    padding-bottom: 7rem;
  }

  .solutionsSubPageHeader img.stroke {
    width: 98px;
    top: 40px;
    right: -50px;
  }

  .solutionsSubPageHeader img.line {
    bottom: 9px;
    left: -53px;
  }

  .solutionItem {
    padding: 0 77px;
    margin-bottom: 89px;
  }

  .solutionItem .d-flex {
    flex-direction: column;
  }

  .solutionItemInfo {
    margin-right: 0;
  }

  .solutionItemTag {
    margin-bottom: 18px;
  }

  .solutionItemTitle {
    margin-bottom: 30px;
  }

  .solutionItemDescription {
    margin-bottom: 0 !important;
  }

  .solutionItemSubItem {
    display: none;
  }

  .solutionItemImg {
    width: 100%;
    margin-bottom: 45px;
  }

  .solutionItem.featured {
    padding-top: 103px;
    padding-bottom: 63px;
  }
}

@media screen and (max-width: 767px) {
  .solutionsSubPageHeader {
    padding-top: 2.625rem;;
    padding-bottom: 5.5rem;
  }

  .solutionsSubPageHeaderTag {
    margin-bottom: 18px;
  }

  .solutionsSubPageHeaderTitle {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 1.125rem;
    padding: 0 1.75rem;
  }

  .solutionsSubPageHeaderDescription {
    font-size: 0.875rem;
    margin-bottom: 2rem;
    padding: 0 2.5rem;
  }

  .solutionsUsageTitle {
    font-size: 0.875rem;
  }

  .solutionsUsageItemsGroup {
    width: 100%;
    padding: 0 22px;
    justify-content: space-between;
  }

  .solutionsUsageItems {
    margin-right: 0;
  }

  .solutionsUsageItem {
    font-size: 14px;
  }

  .solutionsSubPageHeader .getStarted {
    font-size: 16px;
    line-height: 19px;
  }

  .solutionsSubPageHeader img.stroke {
    width: 88px;
    top: 17px;
    right: -32px;
  }

  .solutionsSubPageHeader img.line {
    width: 149px;
    bottom: 33px;
    left: -69px;
  }

  .solutionItemPartnersPanel {
    display: none;
  }

  .solutionItem .d-flex {
    flex-direction: column;
  }

  .solutionItem {
    padding: 0 38px;
    margin-bottom: 57px;
  }

  .solutionItemInfo {
    margin-right: 0;
  }

  .solutionItemTag {
    margin-bottom: 18px;
  }

  .solutionItemTitle {
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 15px;
  }

  .solutionItemDescription {
    font-size: 14px;
    margin-bottom: 0 !important;
  }

  .solutionItemSubItem {
    display: none;
  }

  .solutionItemImg {
    width: 100%;
    margin-bottom: 18px;
  }

  .solutionItem.featured {
    padding-top: 55px;
    padding-bottom: 45px;
  }
}
